import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ColorSwatchGrid from '../../../components/ColorSwatchGrid';
import ColorSwatch from '../../../components/ColorSwatch';
import doRed from '../../../images/do-red.png';
import doRed2x from '../../../images/do-red@2x.png';
import dontRed from '../../../images/dont-red.png';
import dontRed2x from '../../../images/dont-red@2x.png';
import doPurple from '../../../images/do-purple.png';
import doPurple2x from '../../../images/do-purple@2x.png';
import dontPurple from '../../../images/dont-purple.png';
import dontPurple2x from '../../../images/dont-purple@2x.png';
import doGray from '../../../images/do-gray.png';
import doGray2x from '../../../images/do-gray@2x.png';
import dontGray from '../../../images/dont-gray.png';
import dontGray2x from '../../../images/dont-gray@2x.png';
import doSemantic from '../../../images/do-semantic.png';
import doSemantic2x from '../../../images/do-semantic@2x.png';
import dontSemantic from '../../../images/dont-semantic.png';
import dontSemantic2x from '../../../images/dont-semantic@2x.png';
import stepperExample from '../../../images/stepper-example.png';
import stepperExample2x from '../../../images/stepper-example@2x.png';
import stepperExample3x from '../../../images/stepper-example@3x.png';
import chart1 from '../../../images/chart-1.png';
import chart12x from '../../../images/chart-1@2x.png';
import chart2 from '../../../images/chart-2.png';
import chart22x from '../../../images/chart-2@2x.png';
import howToApplyColorProportionally from '../../../images/how-to-apply-color-proportionally.png';
import howToApplyColorProportionally2x from '../../../images/how-to-apply-color-proportionally@2x.png';
import { SprkStack, SprkStackItem, SprkButton, SprkFlag, SprkHeading, SprkLink, SprkInputContainer, SprkLabel, SprkInput, SprkIcon, SprkDivider, SprkText } from '@sparkdesignsystem/spark-react';
export const doRedSet = `${doRed} 560w, ${doRed2x} 1120w`;
export const dontRedSet = `${dontRed} 560w, ${dontRed2x} 1120w`;
export const doPurpleSet = `${doPurple} 560w, ${doPurple2x} 1120w`;
export const dontPurpleSet = `${dontPurple} 560w, ${dontPurple2x} 1120w`;
export const doGraySet = `${doGray} 560w, ${doGray2x} 1120w`;
export const dontGraySet = `${dontGray} 560w, ${dontGray2x} 1120w`;
export const doSemanticSet = `${doSemantic} 560w, ${doSemantic2x} 1120w`;
export const dontSemanticSet = `${dontSemantic} 560w, ${dontSemantic2x} 1120w`;
export const stepperExampleSet = `${stepperExample} 560w, ${stepperExample2x} 1120w, ${stepperExample3x} 1680w`;
export const chart1Set = `${chart1} 560w, ${chart12x} 1120w`;
export const chart2Set = `${chart2} 560w, ${chart22x} 1120w`;
export const howToApplyColorProportionallySet = `${howToApplyColorProportionally} 560w, ${howToApplyColorProportionally2x} 1120w`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  doRedSet,
  dontRedSet,
  doPurpleSet,
  dontPurpleSet,
  doGraySet,
  dontGraySet,
  doSemanticSet,
  dontSemanticSet,
  stepperExampleSet,
  chart1Set,
  chart2Set,
  howToApplyColorProportionallySet,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




























    <SprkHeading isPageTitle variant="displayOne" additionalClasses="docs-b-h1 sprk-u-mbl sprk-u-Measure" mdxType="SprkHeading">
  Color Usage
    </SprkHeading>
    <blockquote className="docs-b-Blockquote sprk-u-mvl sprk-u-Measure">
  We've upgraded to the Orbit color palette in August 2020!
  Review this guide for new color guidelines.
    </blockquote>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <SprkHeading element="h2" variant="displayTwo" additionalClasses="docs-b-h2 sprk-u-mbl sprk-u-Measure" mdxType="SprkHeading">
  Table of Contents
    </SprkHeading>
    <ul>
  <li>
    <a href="#our-color-story">
      Our Color Story
    </a>
  </li>
  <li>
    <a href="#how-to-apply-color">
      How To Apply Color
    </a>
    <ul className="sprk-b-List sprk-b-List--indented sprk-u-Measure sprk-u-mvs">
      <li>
        <a href="#rocket-red">
          Rocket Red
        </a>
      </li>
      <li>
        <a href="#orbit-purple">
          Orbit Purple
        </a>
      </li>
      <li>
        <a href="#neutral-colors">
          Neutral Colors
        </a>
      </li>
      <li>
        <a href="#semantic-colors">
          Semantic Colors
        </a>
      </li>
      <li>
        <a href="#floods-of-color">
          Floods of Color
        </a>
      </li>
    </ul>
  </li>
  <li>
    <a href="#charts-and-graphs">
      Charts and Graphs
    </a>
  </li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <SprkHeading element="h2" variant="displayTwo" additionalClasses="docs-b-h2 sprk-u-mbl sprk-u-Measure" mdxType="SprkHeading">
  Our Color Story
    </SprkHeading>
    <SprkText additionalClasses="sprk-u-Measure sprk-u-mbm" mdxType="SprkText">
  The Orbit palette conveys the tapestry of colors seen from a rocket as it ascends into space. Our color choices capture the evolving hue and brilliance of the earth's atmospheres.
    </SprkText>
    <SprkText additionalClasses="sprk-u-Measure" mdxType="SprkText">
  We've employed color psychology and user testing to develop this palette. It's built for modern, human-centered, and accessible experiences.
    </SprkText>
    <blockquote className="docs-b-Blockquote sprk-u-mvl sprk-u-Measure">
  Our color usage guidelines follow <a href="/using-spark/foundations/color-accessibility">WCAG 2.1 (Level AA)</a> contrast standards.
    </blockquote>
    <div className="sprk-u-TextAlign--right sprk-u-mbl">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <SprkHeading element="h2" variant="displayTwo" additionalClasses="docs-b-h2 sprk-u-mbl sprk-u-Measure" mdxType="SprkHeading">
  How to Apply Color
    </SprkHeading>
    <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbl sprk-u-Measure" mdxType="SprkText">
  When observing the amounts of colors used in a project, it should look similar to the color bar below.
    </SprkText>
    <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbl sprk-u-Measure" mdxType="SprkText">
  A well balanced color proportion leans towards primarily white backgrounds with black text, followed by intentionally placed interaction and brand colors.
    </SprkText>
    <img srcSet={howToApplyColorProportionallySet} src={howToApplyColorProportionally} sizes="550px" alt="Scale showing correct balance and proportions when using color." />
    <SprkHeading element="h3" variant="displayThree" additionalClasses="docs-b-h3 sprk-u-mbm sprk-u-Measure" mdxType="SprkHeading">
  Rocket Red
    </SprkHeading>
    <ColorSwatchGrid additionalClasses="sprk-u-mbm" mdxType="ColorSwatchGrid">
  <ColorSwatch isCompact variableName="sprk-red" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-deep-red" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-mid-red" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbl sprk-u-Measure" mdxType="SprkText">
  Use Rocket Red for primary actions, like buttons and key elements. This primary color highlights the main actions on the page.
    </SprkText>
    <div className="sprk-u-mbh sprk-u-mtl">
  <SprkButton additionalClasses="sprk-u-mrm" mdxType="SprkButton">Button</SprkButton>
    </div>
    <SprkStack itemSpacing="medium" splitAt="small" additionalClasses="sprk-u-mbl" mdxType="SprkStack">
  <SprkStackItem additionalClasses="sprk-o-Stack__item--half@s sprk-u-prm" mdxType="SprkStackItem">
    <SprkStack mdxType="SprkStack">
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <SprkFlag additionalClasses="sprk-o-Flag--middle" media={<SprkIcon iconName="check-mark" additionalClasses="sprk-c-Icon--l sprk-c-Icon--stroke-current-color sprk-c-Icon--filled-current-color sprk-u-Color--green" mdxType="SprkIcon" />} mdxType="SprkFlag">
          <SprkHeading element="span" variant="displayFour" mdxType="SprkHeading">
            Do
          </SprkHeading>
        </SprkFlag>
      </SprkStackItem>
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <img srcSet={doRedSet} src={doRed} sizes="(min-width: 1120px) 1120px,
                (min-width: 1680px) 1680px,
                560px" alt="Webpage with a Rocket Red primary button." />
      </SprkStackItem>
      <SprkStackItem mdxType="SprkStackItem">
        <SprkText variant="displayTwo" additionalClasses="sprk-u-Measure sprk-u-mbm" mdxType="SprkText">
          After placing a primary button, use secondary or tertiary buttons to maintain a clear hierarchy between actions.
        </SprkText>
      </SprkStackItem>
    </SprkStack>
  </SprkStackItem>
  <SprkStackItem additionalClasses="sprk-o-Stack__item--half@s sprk-u-prm" mdxType="SprkStackItem">
    <SprkStack mdxType="SprkStack">
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <SprkFlag additionalClasses="sprk-o-Flag--middle" media={<SprkIcon iconName="close-circle" additionalClasses="sprk-c-Icon--l sprk-c-Icon--stroke-current-color sprk-c-Icon--filled-current-color sprk-u-Color--red" mdxType="SprkIcon" />} mdxType="SprkFlag">
          <SprkHeading element="span" variant="displayFour" mdxType="SprkHeading">
            Don't
          </SprkHeading>
        </SprkFlag>
      </SprkStackItem>
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <img srcSet={dontRedSet} src={dontRed} sizes="(min-width: 1120px) 1120px,
                (min-width: 1680px) 1680px,
                560px" alt="Webpage with two Rocket Red primary buttons next to each other." />
      </SprkStackItem>
      <SprkStackItem mdxType="SprkStackItem">
        <SprkText variant="displayTwo" additionalClasses="sprk-u-Measure sprk-u-mbm" mdxType="SprkText">
          Overusing Rocket Red causes distraction from the main action. It's especially distracting when two primary buttons are close to each other.
        </SprkText>
      </SprkStackItem>
    </SprkStack>
  </SprkStackItem>
    </SprkStack>
    <div className="sprk-u-TextAlign--right sprk-u-mbl">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <SprkHeading element="h3" variant="displayThree" additionalClasses="docs-b-h3 sprk-u-mbm sprk-u-Measure" mdxType="SprkHeading">
  Orbit Purple
    </SprkHeading>
    <ColorSwatchGrid additionalClasses="sprk-u-mbm" mdxType="ColorSwatchGrid">
  <ColorSwatch isCompact variableName="sprk-purple" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-purple-dark" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-purple-deep" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-purple-light" mdxType="ColorSwatch" />
  <ColorSwatch hasOutline isCompact variableName="sprk-purple-lightest" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbl sprk-u-Measure" mdxType="SprkText">
  Use Purple to highlight interactive elements, like buttons, links, and active form fields. It can also spotlight important information.
    </SprkText>
    <SprkStack splitAt="small" itemSpacing="huge" additionalClasses="sprk-o-Stack--center-column sprk-u-mbh" mdxType="SprkStack">
  <SprkStackItem mdxType="SprkStackItem">
    <SprkButton variant="secondary" mdxType="SprkButton">Button</SprkButton>
  </SprkStackItem>
  <SprkStackItem mdxType="SprkStackItem">
    <SprkButton variant="tertiary" mdxType="SprkButton">Button</SprkButton>
  </SprkStackItem>
  <SprkStackItem mdxType="SprkStackItem">
    <SprkLink element="a" href="#nogo" mdxType="SprkLink">
      link
    </SprkLink>
  </SprkStackItem>
  <SprkStackItem mdxType="SprkStackItem">
    <SprkInputContainer mdxType="SprkInputContainer">
    <SprkLabel htmlFor="text-input" className="sprk-b-Label" mdxType="SprkLabel">
      Input
    </SprkLabel>
    <SprkInput id="text-input" defaultValue="Focus to see styling" mdxType="SprkInput" />
    </SprkInputContainer>
  </SprkStackItem>
    </SprkStack>
    <SprkStack itemSpacing="medium" splitAt="small" additionalClasses="sprk-u-mbl" mdxType="SprkStack">
  <SprkStackItem additionalClasses="sprk-o-Stack__item--half@s sprk-u-prm" mdxType="SprkStackItem">
    <SprkStack mdxType="SprkStack">
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <SprkFlag additionalClasses="sprk-o-Flag--middle" media={<SprkIcon iconName="check-mark" additionalClasses="sprk-c-Icon--l sprk-c-Icon--stroke-current-color sprk-c-Icon--filled-current-color sprk-u-Color--green" mdxType="SprkIcon" />} mdxType="SprkFlag">
          <SprkHeading element="span" variant="displayFour" mdxType="SprkHeading">
            Do
          </SprkHeading>
        </SprkFlag>
      </SprkStackItem>
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <img srcSet={doPurpleSet} src={doPurple} sizes="(min-width: 1120px) 1120px,
                (min-width: 1680px) 1680px,
                560px" alt="Webpage with an Orbit Purple field input to indicate interaction." />
      </SprkStackItem>
      <SprkStackItem mdxType="SprkStackItem">
        <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkText">
          Use Orbit Purple to call attention to interactive elements.
        </SprkText>
      </SprkStackItem>
    </SprkStack>
  </SprkStackItem>
  <SprkStackItem additionalClasses="sprk-o-Stack__item--half@s sprk-u-prm" mdxType="SprkStackItem">
    <SprkStack mdxType="SprkStack">
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <SprkFlag additionalClasses="sprk-o-Flag--middle" media={<SprkIcon iconName="close-circle" additionalClasses="sprk-c-Icon--l sprk-c-Icon--stroke-current-color sprk-c-Icon--filled-current-color sprk-u-Color--red" mdxType="SprkIcon" />} mdxType="SprkFlag">
          <SprkHeading element="span" variant="displayFour" mdxType="SprkHeading">
            Don't
          </SprkHeading>
        </SprkFlag>
      </SprkStackItem>
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <img srcSet={dontPurpleSet} src={dontPurple} sizes="(min-width: 1120px) 1120px,
                (min-width: 1680px) 1680px,
                560px" alt="Webpage with Purple text and an Orbit Purple background." />
      </SprkStackItem>
      <SprkStackItem mdxType="SprkStackItem">
        <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkText">
          Overusing Purple distracts from interactive elements on the page.
        </SprkText>
      </SprkStackItem>
    </SprkStack>
  </SprkStackItem>
    </SprkStack>
    <div className="sprk-u-TextAlign--right sprk-u-mbl">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <SprkHeading element="h3" variant="displayThree" additionalClasses="docs-b-h3 sprk-u-mbm sprk-u-Measure" mdxType="SprkHeading">
  Neutral Colors
    </SprkHeading>
    <ColorSwatchGrid additionalClasses="sprk-u-mbm" mdxType="ColorSwatchGrid">
  <ColorSwatch isCompact variableName="sprk-black" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-black-tint-80" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-black-tint-70" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-black-tint-60" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-black-tint-50" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-black-tint-40" mdxType="ColorSwatch" />
  <ColorSwatch hasOutline isCompact variableName="sprk-black-tint-30" mdxType="ColorSwatch" />
  <ColorSwatch hasOutline isCompact variableName="sprk-black-tint-20" mdxType="ColorSwatch" />
  <ColorSwatch hasOutline isCompact variableName="sprk-black-tint-10" mdxType="ColorSwatch" />
  <ColorSwatch hasOutline isCompact variableName="sprk-gray" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkText">
  Use for text, borders, and system icons.
    </SprkText>
    <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbl sprk-u-Measure" mdxType="SprkText">
  Most text should be black, with some additional shades of dark gray for content hierarchy. Follow <a href="/using-spark/foundations/color-accessibility">contrast accessibility standards</a> when considering anything but black text on white backgrounds.
    </SprkText>
    <SprkStack itemSpacing="medium" splitAt="small" additionalClasses="sprk-u-mbl" mdxType="SprkStack">
  <SprkStackItem additionalClasses="sprk-o-Stack__item--half@s sprk-u-prm" mdxType="SprkStackItem">
    <SprkStack mdxType="SprkStack">
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <SprkFlag additionalClasses="sprk-o-Flag--middle" media={<SprkIcon iconName="check-mark" additionalClasses="sprk-c-Icon--l sprk-c-Icon--stroke-current-color sprk-c-Icon--filled-current-color sprk-u-Color--green" mdxType="SprkIcon" />} mdxType="SprkFlag">
          <SprkHeading element="span" variant="displayFour" mdxType="SprkHeading">
            Do
          </SprkHeading>
        </SprkFlag>
      </SprkStackItem>
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <img srcSet={doGraySet} src={doGray} sizes="(min-width: 1120px) 1120px,
                (min-width: 1680px) 1680px,
                560px" alt="Webpage with headline text in Black, and body copy in corresponding shades of grey to indicate hierarchy." />
      </SprkStackItem>
      <SprkStackItem mdxType="SprkStackItem">
        <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkText">
          Most text should be black, with some additional darker shades of gray for content hierarchy.
        </SprkText>
      </SprkStackItem>
    </SprkStack>
  </SprkStackItem>
  <SprkStackItem additionalClasses="sprk-o-Stack__item--half@s sprk-u-prm" mdxType="SprkStackItem">
    <SprkStack mdxType="SprkStack">
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <SprkFlag additionalClasses="sprk-o-Flag--middle" media={<SprkIcon iconName="close-circle" additionalClasses="sprk-c-Icon--l sprk-c-Icon--stroke-current-color sprk-c-Icon--filled-current-color sprk-u-Color--red" mdxType="SprkIcon" />} mdxType="SprkFlag">
          <SprkHeading element="span" variant="displayFour" mdxType="SprkHeading">
            Don't
          </SprkHeading>
        </SprkFlag>
      </SprkStackItem>
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <img srcSet={dontGraySet} src={dontGray} sizes="(min-width: 1120px) 1120px,
                (min-width: 1680px) 1680px,
                560px" alt="Webpage with headline and body copy text shown in several different semantic and brand colors." />
      </SprkStackItem>
      <SprkStackItem mdxType="SprkStackItem">
        <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkText">
          Don't use any other colors for text like shades of red, purple or semantic colors.
        </SprkText>
      </SprkStackItem>
    </SprkStack>
  </SprkStackItem>
    </SprkStack>
    <div className="sprk-u-TextAlign--right sprk-u-mbl">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <SprkHeading element="h3" variant="displayThree" additionalClasses="docs-b-h3 sprk-u-mbm sprk-u-Measure" mdxType="SprkHeading">
  Semantic Colors
    </SprkHeading>
    <ColorSwatchGrid additionalClasses="sprk-u-mbm" mdxType="ColorSwatchGrid">
  <ColorSwatch isCompact variableName="sprk-green" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-blue" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-orange" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbl sprk-u-Measure" mdxType="SprkText">
  Use to communicate the status of messages.
    </SprkText>
    <ul>
  <li>Green: Success</li>
  <li>Blue: Informational</li>
  <li>Orange: Error, Warning, Urgent or Attention Needed</li>
    </ul>
    <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkText">
  Do not use semantic colors for large accents.
    </SprkText>
    <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbl sprk-u-Measure" mdxType="SprkText">
  Use the Error semantic color to present negative information and reserve Rocket Red for positive brand interactions.
    </SprkText>
    <SprkStack itemSpacing="medium" splitAt="small" additionalClasses="sprk-u-mbl" mdxType="SprkStack">
  <SprkStackItem additionalClasses="sprk-o-Stack__item--half@s sprk-u-prm" mdxType="SprkStackItem">
    <SprkStack mdxType="SprkStack">
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <SprkFlag additionalClasses="sprk-o-Flag--middle" media={<SprkIcon iconName="check-mark" additionalClasses="sprk-c-Icon--l sprk-c-Icon--stroke-current-color sprk-c-Icon--filled-current-color sprk-u-Color--green" mdxType="SprkIcon" />} mdxType="SprkFlag">
          <SprkHeading element="span" variant="displayFour" mdxType="SprkHeading">
            Do
          </SprkHeading>
        </SprkFlag>
      </SprkStackItem>
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <img srcSet={doSemanticSet} src={doSemantic} sizes="(min-width: 1120px) 1120px,
                (min-width: 1680px) 1680px,
                560px" alt="Webpage with a Rocket Red colored alert window, indicating an urgent message." />
      </SprkStackItem>
      <SprkStackItem mdxType="SprkStackItem">
        <SprkText variant="bodyTwo" additionalClasses="sprk-u-Measure sprk-u-mbm" mdxType="SprkText">
          Use appropriate semantic colors to indicate the status of messages, like an urgent alert.
        </SprkText>
      </SprkStackItem>
    </SprkStack>
  </SprkStackItem>
  <SprkStackItem additionalClasses="sprk-o-Stack__item--half@s sprk-u-prm" mdxType="SprkStackItem">
    <SprkStack mdxType="SprkStack">
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <SprkFlag additionalClasses="sprk-o-Flag--middle" media={<SprkIcon iconName="close-circle" additionalClasses="sprk-c-Icon--l sprk-c-Icon--stroke-current-color sprk-c-Icon--filled-current-color sprk-u-Color--red" mdxType="SprkIcon" />} mdxType="SprkFlag">
          <SprkHeading element="span" variant="displayFour" mdxType="SprkHeading">
            Don't
          </SprkHeading>
        </SprkFlag>
      </SprkStackItem>
      <SprkStackItem additionalClasses="sprk-u-mbm" mdxType="SprkStackItem">
        <img srcSet={dontSemanticSet} src={dontSemantic} sizes="(min-width: 1120px) 1120px,
                (min-width: 1680px) 1680px,
                560px" alt="Webpage with a Semantic Warning color background." />
      </SprkStackItem>
      <SprkStackItem mdxType="SprkStackItem">
        <SprkText variant="bodyTwo" additionalClasses="sprk-u-Measure sprk-u-mbm" mdxType="SprkText">
          Use semantic colors to communicate status. Using them in other places, distracts users from focusing on vital information. Don't use them as accents or action colors.
        </SprkText>
      </SprkStackItem>
    </SprkStack>
  </SprkStackItem>
    </SprkStack>
    <div className="sprk-u-TextAlign--right sprk-u-mbl">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <SprkHeading element="h3" variant="displayThree" additionalClasses="docs-b-h3 sprk-u-mbm sprk-u-Measure" mdxType="SprkHeading">
  Floods of Color
    </SprkHeading>
    <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkText">
  Most content should have a white background. To create other sections of focus (for example for a <a href="/using-spark/components/stepper">Stepper</a> or <a href="/using-spark/components/footer">Footer</a>), use only these dark and light colors.
    </SprkText>
    <img srcSet={stepperExampleSet} src={stepperExample} sizes="550px" className="sprk-u-mbm" alt="Orbit Purple Screen that fills majority of space, paired with a step-by-step navigation bar and blank iPhone screen." />
    <SprkHeading element="h4" variant="displayFour" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkHeading">
  Dark
    </SprkHeading>
    <ColorSwatchGrid additionalClasses="sprk-u-mbm" mdxType="ColorSwatchGrid">
  <ColorSwatch isCompact variableName="sprk-black" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-purple-dark" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-purple-deep" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <SprkHeading element="h4" variant="displayFour" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkHeading">
  Light
    </SprkHeading>
    <ColorSwatchGrid additionalClasses="sprk-u-mbl" mdxType="ColorSwatchGrid">
  <ColorSwatch isCompact hasOutline variableName="sprk-white" mdxType="ColorSwatch" />
  <ColorSwatch isCompact hasOutline variableName="sprk-purple-lightest" mdxType="ColorSwatch" />
  <ColorSwatch isCompact hasOutline variableName="sprk-gray" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <div className="sprk-u-TextAlign--right sprk-u-mbl">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <SprkHeading element="h3" variant="displayThree" additionalClasses="docs-b-h3 sprk-u-mbm sprk-u-Measure" mdxType="SprkHeading">
  Charts and Graphs
    </SprkHeading>
    <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkText">
  Use these shades of purple to communicate information hierarchy in charts and graphs.
    </SprkText>
    <ColorSwatchGrid additionalClasses="sprk-u-mbm" mdxType="ColorSwatchGrid">
  <ColorSwatch isCompact variableName="sprk-purple-navy" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-purple-deep" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-purple-dark" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-purple" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-purple-light" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-lavender" mdxType="ColorSwatch" />
  <ColorSwatch isCompact variableName="sprk-wisteria" mdxType="ColorSwatch" />
  <ColorSwatch hasOutline isCompact variableName="sprk-magnolia" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbm sprk-u-Measure" mdxType="SprkText">
  Use the <a href="#semantic-colors">orange semantic error color</a> to represent a negative element in the chart.
    </SprkText>
    <SprkText variant="bodyTwo" additionalClasses="sprk-u-mbl sprk-u-Measure" mdxType="SprkText">
  Only use other semantic colors as accents to the Purple gradient palette.
    </SprkText>
    <SprkStack itemSpacing="medium" splitAt="small" additionalClasses="sprk-u-Measure sprk-u-mbl" mdxType="SprkStack">
  <SprkStackItem additionalClasses="sprk-o-Stack__item--half@s sprk-u-prm" mdxType="SprkStackItem">
    <img srcSet={chart1Set} src={chart1} sizes="300px" className="sprk-u-mbm" alt="Orbit Purple Screen that fills majority of space, paired with a step-by-step navigation bar and blank iPhone screen." />
  </SprkStackItem>
  <SprkStackItem additionalClasses="sprk-o-Stack__item--half@s sprk-u-prm" mdxType="SprkStackItem">
    <img srcSet={chart2Set} src={chart2} sizes="300px" className="sprk-u-mbm" alt="Orbit Purple Screen that fills majority of space, paired with a step-by-step navigation bar and blank iPhone screen." />
  </SprkStackItem>
    </SprkStack>
    <div className="sprk-u-TextAlign--right sprk-u-mbl">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <SprkDivider element="span" additionalClasses="sprk-u-mbl" mdxType="SprkDivider" />
    <SprkLink element="a" href="/using-spark/foundations/color-codes" variant="has-icon" mdxType="SprkLink">
  Next: Color Codes
  <SprkIcon additionalClasses="
      sprk-u-mls
      sprk-c-Icon--m
      sprk-c-Icon--stroke-current-color" iconName="arrow-right" mdxType="SprkIcon" />
    </SprkLink>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      